import React from "react"
import { Helmet } from "react-helmet"
import Layout from "../components/Layout"
import { notfound } from "./../styles/404.module.scss"

function NotFound404() {
  return (
    <Layout>
      <Helmet>
        <title>
          Halaman Tidak Ditemukan | Grand Wisata Bekasi | Kota Mandiri
          Terintegrasi dan Terencana di Timur Jakarta
        </title>
        <meta name="description" content="halaman tidak ditemukan" />
        <meta
          name="keywords"
          content="grand wisata bekasi,grand wisata,sinar mas land,properti,real estate,rumah"
        />
        <meta name="distribution" content="web" />
      </Helmet>
      <section className="container">
        <div className="row">
          <div
            className={` col-12 d-flex justify-content-center align-items-center`}
          >
            <div className={notfound}>
              {/* <FontAwesomeIcon icon={faExclamationTriangle} /> */}
              <h1>404</h1>
              <p>Maaf, halaman yang anda cari tidak ditemukan.</p>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default NotFound404
